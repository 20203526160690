<template>
  <div class="ExamBindSetQuestion">
    <ExamDetailsHeader
      router-url="unionExamBindQuestion"
      :query="$route.query"
    ></ExamDetailsHeader>
    <div class="table-box">
      <template v-if="progressbarType">
        <el-progress
          class="el-progress-animation"
          :percentage="progressbarVal"
        ></el-progress>
        <p style="color: red; margin-top: 8px">{{ progressText }}</p>
      </template>
      <template v-else>
        <el-table :data="answerAreaArr" border style="width: 100%">
          <el-table-column
            prop="bindQuestionNum"
            label="绑定题号"
            width="220"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="阅卷区域"
            width="220"
            align="center"
          >
            <el-button type="text" @click="showImg()">查看</el-button>
            <!-- <el-button type="text">反面</el-button> -->
          </el-table-column>
          <el-table-column prop="address" label="调整阅卷区域" align="center">
            <template slot-scope="scope">
              <span
                v-for="(v, i) in scope.row.answerArea"
                :key="i"
                class="btn-list"
              >
                <el-button type="text" @click="showAre(scope.row, i)">
                  <template v-if="scope.row.answerArea.length == 1">
                    调整区域
                  </template>
                  <template v-else>调整第{{ i + 1 }}部分</template>
                </el-button>
                <el-button
                  v-if="scope.row.answerArea.length > 1"
                  type="text"
                  @click="delAre(scope.row, i)"
                >
                  （删除）
                </el-button>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="添加区域"
            width="220"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="addAre(scope.row, 0)"
                >正面</el-button
              >
              <el-button type="text" @click="addAre(scope.row, 1)"
                >反面</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-button
          type="primary"
          class="submit-btn"
          icon="el-icon-edit"
          :loading="btnLoading"
          @click="exammarkSaveBindQuestion()"
          >保存</el-button
        >
        <div class="tips">
          <div>注意：</div>
          <p>
            1、绑定小题在阅卷时使用一张图片，需要手动调整阅卷区域（默认为绑定小题全部区域纵向排列）。
          </p>
          <p>2、调整完成后必须点击‘保存’按钮才能保存。</p>

          <p>
            3、一旦开始阅卷后，系统不支持删除绑定，请慎重操作！（如若删除必须重评）
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ExamDetailsHeader from "@/components/detailsHeader";
import {
  questionCoordinate,
  questionArea,
  exammarkSaveBindQuestion,
  progressbar,
} from "@/core/api/exam/examBindQuestion";
import { getStore, setStore } from "@/core/util/store";
import { getObj } from "@/core/api/exam/exam";
export default {
  name: "ExamBindSetQuestion",
  components: {
    ExamDetailsHeader,
  },
  data() {
    return {
      progressText: "",
      queryData: {},
      examInformation: {},
      answerAreaArr: [],
      btnLoading: false,
      progressbarType: false,
      progressbarVal: 0,
      name: "",
      subjectName: "",
    };
  },
  created() {
    this.initView();
    this.getObj();
    // console.log(this.$route.query.code);
  },
  destroyed() {
    clearTimeout(this.getProgressbar);
  },
  methods: {
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.name = res.data.data.name;
        res.data.data.paperList.map((item) => {
          if (item.paperId == this.$route.query.paperId) {
            this.subjectName = item.subjectName;
          }
        });
      });
    },
    progressbar() {
      let { bindQuestionNum, sheetDetailIds } = this.$route.query;
      let data = {
        examPaperId: this.$route.query.examPaperId,
        questionNum: bindQuestionNum,
        sheetDetailIds: sheetDetailIds,
      };
      this.progressbarType = true;
      progressbar(data).then((res) => {
        this.progressbarVal = Number((res.data.data.rate * 100).toFixed(2));
        this.progressText = res.data.data.msg;
        if (this.progressbarVal == 100) {
          this.$alert(
            `小题${this.$route.query.bindQuestionNum}绑定成功，返回列表页!`,
            "提示",
            {
              confirmButtonText: "确定",
              callback: () => {
                let query = this.$route.query;
                delete query.questionNum;
                delete query.sheetDetailIds;
                delete query.bindQuestionNum;
                this.$router.push({
                  name: "unionExamBindQuestion",
                  query: this.$route.query,
                });
              },
            }
          );
          clearTimeout(this.getProgressbar);
        } else {
          this.getProgressbar = setTimeout(() => {
            this.progressbar();
          }, 1000);
        }
      });
    },
    exammarkSaveBindQuestion() {
      let str = JSON.stringify(this.answerAreaArr[0].answerArea);
      str = str.substring(0, str.length - 1);
      str = str.substring(1);
      let data = {
        answerArea: str,
        examPaperId: this.$route.query.examPaperId,
        bindQuestionNum: this.$route.query.bindQuestionNum,
        sheetDetailIds: this.$route.query.sheetDetailIds,
      };
      this.btnLoading = true;
      if (this.$route.query.type == "edit") {
        data.id = this.answerAreaId;
      }
      exammarkSaveBindQuestion(data)
        .then(() => {
          this.progressbar();
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // exammarkPutBindQuestion(data) {
    //   this.btnLoading = true;
    //   exammarkSaveBindQuestion(data)
    //     .then(() => {
    //       this.progressbar();
    //       this.btnLoading = false;
    //     })
    //     .catch(() => {
    //       this.btnLoading = false;
    //     });
    // },
    // 显示答题卡图片
    showImg() {
      let data = {
        // bindQuestionNum: item.bindQuestionNum,
        examPaperId: this.queryData.paperId,
        type: "newAre",
      };
      const routeData = this.$router.resolve({
        name: "ExamBindImg",
        query: data,
      });
      window.open(routeData.href, "_blank");
    },
    // 调整区域
    showAre(val, i) {
      let data = {
        examPaperId: this.examPaperId,
        indexArea: i,
        priority: val.answerArea[i].priority,
      };
      data.type = "edit";
      data.fromPages = "bind";
      val.urls.forEach((item, index) => {
        if (val.answerArea[i].priority == index + 1) {
          data.urls = item;
        }
      });
      this.$router.push({
        name: "ExamAdjustArea",
        query: data,
      });
    },
    // 删除区域
    delAre(val, i) {
      val.answerArea.splice(i, 1);
      setStore({
        name: "bind_awswer_Area",
        content: JSON.stringify(this.answerAreaArr),
        type: 1,
      });
    },
    // 添加区域
    addAre(val, i) {
      let data = {
        examPaperId: this.examPaperId,
        indexArea: i,
        priority: i + 1,
      };
      data.type = "add";
      data.fromPages = "bind";
      // console.log(val);
      data.urls = val.urls[i];
      this.$router.push({
        name: "ExamAdjustArea",
        query: data,
      });
    },
    async questionArea() {
      const res = await questionArea({
        examPaperId: this.$route.query.examPaperId,
        type: 2,
      });
      this.urls = res.data.data;
      // console.log(res);
    },
    initView() {
      this.queryData = this.$route.query;
      let answerArea = getStore({ name: "bind_awswer_Area" });

      if (answerArea) {
        //
        this.answerAreaArr = JSON.parse(answerArea);
        this.answerAreaId = getStore({ name: "bind_awswer_Area_id" });
        // console.log(this.answerAreaArr);
      } else {
        this.questionArea();
        this.questionCoordinate();
      }
    },
    questionCoordinate() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      questionCoordinate(data).then((res) => {
        // console.log(res);
        let { bindQuestionNum } = this.$route.query;
        let array = bindQuestionNum.split(",");
        let bindItem = res.data.data.filter(
          (item) => array.indexOf(item.examQuestionNumber) != -1
        );
        bindItem = bindItem.map((item) => item.answerArea);
        let answerArea = `[${bindItem.join(",")}]`;
        this.answerAreaArr = [];
        // this.$nextTick(() => {
        this.answerAreaArr.push({
          bindQuestionNum: bindQuestionNum,
          answerArea: JSON.parse(answerArea),
          urls: this.urls,
        });
        // });
        this.answerAreaArr = JSON.parse(JSON.stringify(this.answerAreaArr));
        setStore({
          name: "bind_awswer_Area",
          content: JSON.stringify(this.answerAreaArr),
          type: 1,
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@keyframes move {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 200px;
  }
}
.ExamBindSetQuestion {
  padding-top: 24px;
  .submit-btn {
    display: block;
    margin: 24px auto 0;
  }
  .tips {
    padding: 14px 24px 14px 34px;
    margin-top: 24px;
    background: #fdf0ee;
    border: 1px dashed #ff7575;
    color: #777473;
    div {
      line-height: 14px;
      margin-bottom: 12px;
      position: relative;
      &::after {
        content: "!";
        position: absolute;
        letter-spacing: 0;
        width: 13px;
        height: 13px;
        text-align: center;
        line-height: 13px;
        border-radius: 50%;
        background-color: #f17e5e;
        left: -21px;
        font-size: 12px;
        top: 0.5;
        color: #ffffff;
      }
    }
    p {
      margin-bottom: 12px;
    }
  }
  .btn-list {
    .el-button + .el-button {
      margin-left: 0;
    }
  }
  .btn-list + .btn-list {
    margin-left: 12px;
  }
  .table-box {
    margin-top: 24px;
    padding: 24px;
    background-color: #ffffff;
  }
}
</style>
